import { useReducer, createContext } from "react";
import { Reducer } from "./reducers/index";

// initial state
const initialState = {
    campaign: {
        nextActivePage: "create",
        basicInfo: {
            campaignName: "",
            campaignType: "",
            role: "",
            messageType: ""
        },
        message: {
            attachment: {
                attachment: "",
                message: ""
            },
            cardWithAttachment: {
                attachment: "",
                config: ""
            },
            adaptiveCard: {
                config: ""
            }
        },
        receipent: {
            audienceFormat: "",
            file: "",
            org: {
                orgId: "",
                orgName: "",
                targetAudience: "",
                filter: ""
            }
        }
    }
};

// create context
const Context = createContext({});

// context provider
const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const value = { state, dispatch };
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };