export function Reducer(state, action) {
    switch (action.type) {
        case "ACTIVE_PAGE":
            return { ...state, campaign: { ...state.campaign, nextActivePage: action.payload.nextActivePage }};
        case "CAMPAIGN_BASIC_INFO":
            return { ...state, campaign: { ...state.campaign, nextActivePage: action.payload.nextActivePage, basicInfo: action.payload.basicInfo }};
        case "MSG_TYPE_ATTACHMENT_DELETE_ATTACHMENT":
            return { ...state, campaign: { ...state.campaign, message: { 
                attachment: action.payload.attachment,
                cardWithAttachment: {
                    attachment: "",
                    config: ""
                },
                adaptiveCard: {
                    config: ""
                }
            } }};
        case "MSG_TYPE_ATTACHMENT":
            return { ...state, campaign: { ...state.campaign, nextActivePage: action.payload.nextActivePage, message: { 
                attachment: action.payload.attachment,
                cardWithAttachment: {
                    attachment: "",
                    config: ""
                },
                adaptiveCard: {
                    config: ""
                }
            } }};
        case "MSG_TYPE_CARD_WITH_ATTACHMENT":
            return { ...state, campaign: { ...state.campaign, nextActivePage: action.payload.nextActivePage, message: { 
                attachment: {
                    attachment: "",
                    message: ""
                },
                cardWithAttachment: action.payload.adaptiveCard,
                adaptiveCard: {
                    config: ""
                }
            } }};
        case "MSG_TYPE_CARD_WITH_ATTACHMENT_DELETE_ATTACHMENT":
            return { ...state, campaign: { ...state.campaign, message: { 
                attachment: {
                    attachment: "",
                    message: ""
                },
                cardWithAttachment: action.payload.adaptiveCard,
                adaptiveCard: {
                    config: ""
                }
            } }};
        case "MSG_TYPE_ADAPTIVE_CONFIG":
            return { ...state, campaign: { ...state.campaign, nextActivePage: action.payload.nextActivePage, message: { 
                attachment: {
                    attachment: "",
                    message: ""
                },
                cardWithAttachment: {
                    attachment: "",
                    config: ""
                },
                adaptiveCard: action.payload.adaptiveCard
            } }};
        case "RECEIPENT_ATTACHMENT":
            return { ...state, campaign: { ...state.campaign, nextActivePage: action.payload.nextActivePage, receipent: { audienceFormat: action.payload.audienceFormat, file: action.payload.attachment.file } }};
        case "RECEIPENT_ORG":
            return { ...state, campaign: { ...state.campaign, nextActivePage: action.payload.nextActivePage, receipent: { audienceFormat: action.payload.audienceFormat, org: action.payload.org } }};
        case "NEW_CAMPAING":
            return { ...state, campaign: {
                nextActivePage: "create",
                basicInfo: {
                    campaignName: "",
                    campaignType: "",
                    role: "",
                    messageType: ""
                },
                message: {
                    attachment: {
                        attachment: "",
                        message: ""
                    },
                    cardWithAttachment: {
                        attachment: "",
                        config: ""
                    },
                    adaptiveCard: {
                        config: ""
                    }
                },
                receipent: {
                    audienceFormat: "",
                    file: "",
                    org: {
                        orgId: "",
                        orgName: "",
                        targetAudience: "",
                        filter: ""
                    }
                }
            }};
        default:
            return state;
    }
}