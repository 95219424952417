// default components
import 'bootstrap/dist/css/bootstrap.css'
// custom components
// ...
// custom functions
// ...

export default function ErrorMessage({message}) {
	return (
		<div className='container'>
			<div className='row'>
				<div className='col'>
					<div className='text-center mt-5'></div>
					<h4 className='mt-3 text-center'>{message}</h4>
				</div>
			</div>
		</div>
	)
}