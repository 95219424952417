import axios from "axios";

const getToken = () => {
    if (localStorage.getItem("token")) {
        console.log(localStorage.getItem("token"))
        return JSON.parse(localStorage.getItem("token")).token;
    } else {
        return null;
    }
}

const getCall = async (url, params, token, tokenKey='Token', addOnHeaders = {}) => {
    let tokenHeader = {}
    if(token) {
        tokenHeader.Authorization = `${tokenKey} ${token}`
    }

    return axios.get(url, {
        params: params,
        headers: Object.assign(tokenHeader, addOnHeaders)
    })
    .then((response) => {
        if(response.status == 200) {
            return {status: true, context: response.data}
        } else {
            return {status: false, context: response.data || "No records available"}
        }  
    })
    .catch((err) => {
        return {status: false, context: (err.hasOwnProperty('response') && err.response.hasOwnProperty('data')) ? err.response.data : 'Something went wrong'}
    });
}

const postCall = async (url, bodyData, token, tokenKey='Token', addOnHeaders = {}) => {
    let tokenHeader = {}
    if(token) {
        tokenHeader.Authorization = `${tokenKey} ${token}`
    }
    return axios.post(url, bodyData, {
            headers: Object.assign(tokenHeader, addOnHeaders)
        }
    ).then((response) => {
        if(response.status == 200) {
            return {status: true, context: response.data}
        } else {
            return {status: false, context: response.data || "No records available"}
        }  
    }).catch((err) => {
        console.log('error ', err)
        return {status: false, context: (err.hasOwnProperty('response') && err.response.hasOwnProperty('data')) ? err.response.data : 'Something went wrong'}
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getCall,
    postCall
};
