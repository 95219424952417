// default components
// ...
// custom components
import Spinner from '../../components/spinner';
// custom functions
// ...

export default function ValidateConnection({message}) {
	return (
		<main>
			<div className='container'>
				<div className='row'>
					<div className='col'>
						<div className='text-center mt-5'>
							<Spinner />
						</div>
						<h4 className='mt-3 text-center'>Validating your connection...</h4>
					</div>
				</div>
			</div>
		</main>
	)
}