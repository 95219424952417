// default components
import React, { useContext, Suspense } from "react";
import { Context } from "../../context";
// custom components
import ErrorMessage from '../../components/error';
import HeadTag from '../../components/head-tag';
import Spinner from '../../components/spinner';
// custom functions
import config from "../../config/config";

export default function Campaign({session}) {
	const { state, dispatch } = useContext(Context);
	console.log('state ', state);

	const activePage= (page) => {
		dispatch({
            type: "ACTIVE_PAGE",
            payload: {
				nextActivePage: page
			}
        })
	}

	// Dynamic import...
	const loadPage = config.pagePath[state.campaign.nextActivePage];
	const DynamicCampaignImport = React.lazy(() => import(`./components/${loadPage}/index.js`));

	if(session.hasOwnProperty('status') && session.status) {
		return (
			<Suspense fallback={<div className='text-center mt-5'><Spinner /></div>}>
				<HeadTag />
				<DynamicCampaignImport session={session} activePage={activePage} />
			</Suspense>
		)
	} else if (session.hasOwnProperty('status') && !session.status) {
		return (
			<div>
				<HeadTag />
				<ErrorMessage message={session.context} />
			</div>
		)
	} else {
		return (
			<div>
				<HeadTag />
				<ErrorMessage message={"Something went wrong. Please refresh your browser and try again"} />
			</div>
		)
	}
}