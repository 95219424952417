// default components
import { useEffect, useRef } from "react";
import * as ACData from "adaptivecards-templating";
import * as AdaptiveCards from "adaptivecards";
// custom components
// ...
// custom functions
// ...

export default function Template({config, refresh}) {
    
	const templateElement = useRef();
    useEffect(() => {
        if(config) {
            initConfig();
        } else {
            var olddata=templateElement.current.lastChild;
            if(olddata) {
                templateElement.current.removeChild(olddata);
            }
        }
    }, [config, refresh]);

    const initConfig = () => {
        // Create a Template instance from the template payload
        var initTemplate = new ACData.Template(config);

        // Create a data binding context, and set its $root property to the
        // data object to bind the template to
        var context = ACData.IEvaluationContext = {
            $root : {
                "name": "Adaptive Cards"
            }
        };

        // "Expand" the template - this generates the final Adaptive Card,
        // ready to render
        var card = initTemplate.expand(context);

        var adaptiveCard = new AdaptiveCards.AdaptiveCard();
        adaptiveCard.onProcessMarkdown = function(text, result) {
            result.outputHtml = this.md.render(text)
            result.didProcess = true;
        }

        // Render the card
        if(card) {
            adaptiveCard.parse(card);
        }
        
        if(templateElement.current) {
            var olddata=templateElement.current.lastChild;
            if(olddata) {
                templateElement.current.removeChild(olddata);
            }
            templateElement.current.appendChild(adaptiveCard.render());
        }
    }

    return (
		<div className='bg-white h-100 overflow-auto' ref={templateElement} style={{height: '450px'}}></div>
    );
}