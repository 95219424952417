const config = { 
    services: {
        environment: "prod",
        url: {
            restAPI: {
                prod: "https://csmendusercomm.webex.com:8000",
                stage: "https://csmendusercomm-staging.webex.com:8000",
                local: "http://10.76.128.53:8000",
                vpnMachine: "https://webexbeta.cisco.com:8000"
            },
            webexRedirecUrl: {
                prod: "https://webexapis.com/v1/authorize?client_id=Cd27927b0efe47f865c887cf8562e1fa4e11fc9f20406595b86bef60ddea52e2a&response_type=code&redirect_uri=https%3A%2F%2Fcsmendusercomm.webex.com&scope=spark%3Aall%20spark%3Akms%20spark-admin%3Apeople_read&state=set_state_here",
                stage: "https://webexapis.com/v1/authorize?client_id=C3613164de9ed0eb181fc595fefdbffd7131e9d4b6b951bb84e5124a4572d9738&response_type=code&redirect_uri=https%3A%2F%2Fcsmendusercomm-staging.webex.com%2F&scope=spark%3Aall%20spark%3Akms&state=set_state_here",
                local: "https://webexapis.com/v1/authorize?client_id=C1208c19d36ba5f1b5882e73487a204a0b1d44c9616b9ad3f83e1bf9dfae948dc&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcampaign%2F&scope=spark%3Aall%20spark%3Akms%20spark-admin%3Apeople_read&state=set_state_here"
            }
        },
        notification: {
            enable: false,
            message: "",
            priority: ""
        }
    },
    pagePath: {
        create: "create",
        launch: "launch",
        messageTypeAdaptiveCard: "message-type/adaptive",
        messageTypeAttachment: "message-type/attachment",
        messageTypeAttachmentWithCard: "message-type/attachment-with-card",
        receipent: "receipent"
    },
    test: {
        csmUser: "pinmalli",
        webexToken: "ZTlhY2M2NDgtNzgzYi00NDc0LWJjYzQtODJlNWY0NWYxOTQ4MGIyODM5ODAtYWU4_PF84_1eb65fdf-9643-417f-9974-ad72cae0e10f"
    }
}

export default config;