// default components
import React, {useState,useEffect} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/globals.css';
import './styles/adaptive.css';
// custom components
import {Provider} from './context';
import Campaign from "./pages/campaign";
import Configure from "./pages/configure";
import HeadTag from './components/head-tag';
import ValidateConnection from './pages/campaign/validate-connection';
// custom functions
import apicalls from './utils/apicalls';
import config from './config/config';

export default function MyApp() {
    const [Session, setSession] = useState({});
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const getToken = async (code) => {
        if(code) {
            let apiBody = JSON.stringify({
                "code": code
            });
            return apicalls.postCall(config.services.url.restAPI[config.services.environment]+`/botintegrations/api/authenticate/`, apiBody, '', '', {
                'Content-Type': 'application/json'
            });
        } else {
            return {status: false, context: 'No code available'};
        }
    }

    const validateCode = async () => {
        const code = urlParams.get('code');
        if (code) {
            let apiResp = await getToken(code);
            if(apiResp.status) {
                apiResp.context.code = code;
                setSession(apiResp);
            } else {
                alert(apiResp.context);
            }
        } else {
            window.location.href = config.services.url.webexRedirecUrl[config.services.environment];
        }
    }

    useEffect(() => {
        validateCode();
        // setSession({status: true, context: {token: '', code: '', username: ''}});
    }, []);

    if(Object.keys(Session).length > 0) {
        return (
            <Provider>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/" element={<Campaign session={Session} />} />
                        <Route exact path="/configure" element={<Configure session={Session} />} />
                    </Routes>
                </BrowserRouter>
            </Provider>
        )
    } else {
        return (<div>
            <HeadTag />
            <ValidateConnection />
        </div>)
    }
}