import React, {useState} from 'react';
import Template from "../../components/adaptive-template/template.js"

export default function PreviewModal({config, setshowpreview}) {
    
	return (<>
		<div className="modal fade show d-block" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header" style={{"fontSize":"12px",paddingTop:'5px',paddingBottom:'5px',paddingRight:'5px'}}>
                        {/* <h5 className="modal-title" id="staticBackdropLabel">Preview</h5> */}
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setshowpreview(false)} style={{'opacity':1,}}></button>
                    </div>
                    <div className="modal-body">
                        {config !== ""?<Template config={JSON.parse(config)} refresh={Math.random()} />:<div>Config cannot be empty</div>}
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setshowpreview(false)}>Close</button>
                    </div> */}
                </div>
            </div>
        </div>
	</>
	)
}