import React, {useState} from 'react';
import apicalls from "../../utils/apicalls";
import PreviewModal from './preview-modal';
import config from "../../config/config";

export default function Configure() {
        const [Loading, setLoading] = useState(false);
        const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

        const [Config, setConfig] = useState("");
        const [ShowPreview, setShowPreview] = useState(false);
        const [Email,setEmail] = useState("");
        const [IsLiveCampaign, setIsLiveCampaign] = useState(false);

        const pushData = async () => {
                if(JSON.stringify(Config) === "{}"){
                        alert("Bot Config cannot be empty")
                }else{
                        setLoading(true);
                        const instance = urlParams.get('instance');
                        var ConfigSel = {
                                "contentType": "application/vnd.microsoft.card.adaptive",
                                "content": JSON.parse(Config)
                        }
                        var formdata = new FormData();
                        let js = JSON.stringify(ConfigSel);
                        formdata.append("instance", instance);
                        formdata.append("config", js);
                        let resp = await apicalls.postCall(`https://54.238.218.82:8003/dxm/api/enable/`, formdata, '', '', '');
                        if(resp.status) {
                                alert("Sent Succesfully.");
                        } else {
                                alert("Something went wrong, please try again")
                        }
                }
        }
        const testData =async()=>{
                try { 
                        if(JSON.stringify(Config) === "{}"){
                                alert("Bot Config file cannot be empty")
                        }else{
                                if(Email === ""){
                                        alert("Please provide your Email address to send test campaign")
                                }else{
                                        setLoading(true);
                                        const token = config.test.webexToken;
                                        var ConfigSel = {
                                                "contentType": "application/vnd.microsoft.card.adaptive",
                                                "content": JSON.parse(Config)
                                        }
                                        let apiBody = JSON.stringify({"toPersonEmail": Email, "attachments": ConfigSel, "markdown": "Markdown Test", "text" : "Test Box"});
                                        let apiResp = await apicalls.postCall('https://api.ciscospark.com/v1/messages', apiBody, token, 'Bearer', {
                                                "Content-type": "application/json;charset=utf-8"
                                        });
                                        if(apiResp) {
                                                alert("Text message sent succesfully");
                                                setLoading(false);
                                        } else {
                                                alert("Something went wrong, please try again");
                                                setLoading(false);
                                        }
                                }
                        }
                  } catch (e) { 
                        alert(e)
                        setLoading(false)
                        // expected output: SyntaxError: Unexpected token o in JSON at position 1 
                  } 
        }
        return (<>
                <div style={{margin:"15px"}}> 
                        <div className='row'>
                                <div className='col col-12'>
                                        <textarea id="config" style={{width: '100%', height: '74vh', background: '#000', color: '#fff'}} onChange={(e) => setConfig(e.target.value)}></textarea>
                                </div>
                        </div>
                        <div className='row mt-3'>
                                <div className='col'>
                                        <div className={`d-flex justify-content-between align-items-center`}>
                                                {IsLiveCampaign ? "" : <><div style={{padding:'0', width:'400px'}} className="form-check d-block justify-content-center align-items-center">
                                                        <div className="input-group mb-1 mt-2">
                                                                <input type="text" className="form-control" placeholder="E-mail to run test Campaign" onChange={(e) => setEmail(e.target.value)} aria-label="E-mail to run test Campaign" aria-describedby="button-addon2" />
                                                                <button className="btn btn-outline-secondary btn-primary text-white" type="button" id="button-addon2" disabled={Loading?true:false} onClick={testData}>Test Run</button>
                                                        </div>
                                                </div>

                                                {IsLiveCampaign ? null : <input type="button" className="ms-2 btn btn-dark" value="Preview" onClick={() => setShowPreview(!ShowPreview)} />}
                                                </>}
                                        </div>
                                </div>
                        </div>
                        <div className="form-check mb-1 mt-1">
                                <div className={`d-flex justify-content-between align-items-center`}>
                                        <div>
                                                <input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" checked={IsLiveCampaign} onChange={() => setIsLiveCampaign(!IsLiveCampaign)} />
                                                <label className="form-check-label text-start fs-16rem text-success" htmlFor="flexCheckChecked">I've run test, want to proceed next.</label>
                                        </div>
                                        {IsLiveCampaign ? <input type="button" className='btn btn-primary ' value="Submit" onClick={pushData} /> : null}
                                </div>
                        </div>
                </div>
                {ShowPreview?<PreviewModal config={Config} setshowpreview={setShowPreview} />:null}
        </>
        )
}